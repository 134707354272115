<template>
    <div>
        <router-view />
    </div>
</template>

<script setup>
import config from '@/config'
import { useDataCenter } from '@/managers/dataCenter'
import { onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

const dataCenter = useDataCenter()
const { t } = useI18n()

onBeforeMount(() => {
    if (config.isLocalDevelopment) {
        // 임시 사용자 정보데이터
        // setCookieForYear('UID', '2701858_NoEqupiMB') // 1981_NoEqupiMB(01000001023) / 2002_NoEqupiMB(01000001029)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, 'IOS')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, '2.4.06')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_OS_VERSION, '1.0')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, 'ko')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, '82')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, -540)
    }

    const urlParams = new URLSearchParams(window.location.search)
    // let uid = urlParams.get('uId')
    // if (uid !== undefined && uid !== null) setCookieForYear('UID', uid)
    let appType = urlParams.get('appType')
    if (appType !== undefined && appType !== null) dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, appType)
    let appVersion = urlParams.get('appVersion')
    if (appVersion !== undefined && appVersion !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, appVersion)
    let osVersion = urlParams.get('osVersion')
    if (osVersion !== undefined && osVersion !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_OS_VERSION, osVersion)
    let language = urlParams.get('language')
    if (language !== undefined && language !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, language)
    let country = urlParams.get('country')
    if (country !== undefined && country !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, country)

    let languageCode = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE)
    t.locale = languageCode === undefined ? 'ko' : languageCode

    let utcInterval = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.UTC_INTERVAL)
    if (utcInterval === undefined || utcInterval === null) {
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, -540)
    }
})
onMounted(() => {
})
</script>

<style>
@import './assets/css/normalization.css';
@import './assets/css/iconImage.css';

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
