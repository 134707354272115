import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuexStore'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import messages from '@/assets/language'
import VueGtag from 'vue-gtag'
import config from '@/config'
import Vue3Lottie from 'vue3-lottie'

const i18n = createI18n({
    legacy: false,
    locale: 'ko',
    fallbackLocale: 'ko',
    messages: messages,
})

if (config.buildMode === 'dev') {
    console.log('buildMode DEV')
    createApp(App).use(store).use(createPinia()).use(router).use(i18n).use(Vue3Lottie).mount('#app')
} else {
    console.log('buildMode Prod')
    createApp(App)
        .use(store)
        .use(createPinia())
        .use(router)
        .use(i18n)
        .use(Vue3Lottie)
        .use(
            VueGtag,
            {
                config: {
                    id: 'G-P2JPHJB4FZ', // Google Analytics의 Tracking ID를 넣어준다
                },
            },
            router,
        )
        .mount('#app')
}
