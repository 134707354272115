export default {
    buildMode: 'slot',
    diaryApiBaseUrl: 'https://diaryapi.learnbody.co.kr/',
    diaryReportApiBaseUrl: 'https://dairyreportapi.learnbody.co.kr/',
    guideApiBaseUrl: 'https://guideapi.learnbody.co.kr/',
    marketApiBaseUrl: 'https://marketapi.learnbody.co.kr/',
    storageUrl: 'https://learnbodykr.blob.core.windows.net/',
    cdnUrl: 'https://learnbodykr.blob.core.windows.net/cdn/',
    storageDIR: 'diary-user-photo',
    shopByBaseUrl: 'https://shop-api.e-ncp.com/',
    shopByClientId: 'xRzkr7sOuawIgcDtOgSWSA==',
    communityDomain: 'https://communityapp.learnbody.co.kr/',
    marketDomain: 'https://market.learnbody.co.kr',
    guideDomain: 'https://guide.learnbody.co.kr',
    crewApiBaseUrl: 'https://crewapi.learnbody.co.kr/',
    crewRecordStorageUrl: 'user-crew-mission-photo',
    crewProfileStorageUrl: 'user-crew-profile',
}
